@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap');

body {
  background: var(--dark-blue);
  margin: 0;
  font-family: "Manrope" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 1440px) {
  body {
    min-height: 100vh;
  }
}
